{
  "name": "mol-dashboard",
  "version": "1.0.0",
  "description": "molecule dashboard",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "start": "parcel src/index.html",
    "build": "parcel build src/index.html --no-cache --no-scope-hoist --public-url https://mol.dev/"
  },
  "author": "Andrew White",
  "license": "GNUv3",
  "dependencies": {
    "@tensorflow/tfjs": "^3.17.0",
    "buffer": "^6.0.3",
    "bulma": "^0.9.3",
    "lodash-es": "^4.17.21",
    "parcel": "^2.5.0",
    "smiles-drawer": "^1.2.0",
    "vue": "^3.2.20"
  },
  "devDependencies": {
    "@parcel/optimizer-data-url": "^2.5.0",
    "@parcel/transformer-inline-string": "^2.5.0",
    "@parcel/transformer-sass": "^2.5.0",
    "@parcel/transformer-vue": "^2.5.0",
    "@vue/cli-service": "^5.0.8",
    "eslint": "^8.2.0",
    "eslint-plugin-vue": "^8.0.3"
  },
  "alias": {
    "process": false
  }
}
